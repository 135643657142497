import React from 'react'
import FooterComp from '../components/common/footer'
import UploadBannerComp from '../components/UploadComp/uploadBanner'
import UploadFormComp from '../components/UploadComp/uploadForm'
import UploadFormBannerComp from '../components/UploadComp/uploadFormBanner'
import './uploadPage.scss'

const UploadPage = () => {
  return (
    <>
        <UploadFormBannerComp
          title={`Share your 
          travel stories & stand a chance to win
          an adventure of a lifetime.
          `}
        >
          {/* <p className='my-2 fw-500'>This season, share your journey, visually and interllectually - <br/>#travelwanderer</p> */}
          {/* <hr className='container'/> */}
          <UploadFormComp/>
        </UploadFormBannerComp>
        <FooterComp/>
    </>
  )
}

export default UploadPage