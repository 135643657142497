import React, {useState} from 'react'
import {Accordion} from 'react-bootstrap';
import TitleBoxComp from '../common/titleBox';
import './style.scss'

const FaqBoxComp = () => {
    const [activeKey, setactiveKey] = useState('0')

    const handleSetKey = (i) =>{
        if(activeKey==i){
            setactiveKey('')
        }
        else{
            setactiveKey(i)
        }
    }

    return (
      <div className='container faq-box'>
          <div className="d-flex col-md-12 my-4 justify-content-md-center justify-content-sm-start">
              <div className='text-md-center text-sm-left'>
                  <h1 className='text-black '>FAQ's</h1>
                  <div className='col-md-4 col-4 my-3 text-md-center m-md-auto'>
                          <hr className='yellow-border w-100'/>
                  </div>
              </div>
          </div>
          <Accordion activeKey={activeKey} className="custom-accordion border-0">
              <Accordion.Item onClick={()=>handleSetKey("0")} eventKey="0" className='bg-transparent border-0 shadow-0 p-0'>
                  <Accordion.Header className='bg-transparent border-0 shadow-0 p-0'>
                      <div className="d-flex justify-content-between w-100 px-2">
                          <p className="fs-20 fw-900 mb-0">1. Am I eligible to participate?</p>
                          <p className='d-flex justify-content-end'>
                          {activeKey=='0'?'-':'+'}
                          </p>
                      </div>
                  </Accordion.Header>
                  <Accordion.Body className="text-black">
                  The contest is open to people who has purchased the t shirt with QR code. The contest is open to people aged between 18 -50 years old and with driving licence.
                  </Accordion.Body>
              </Accordion.Item>
              <hr className='yellow-border-light'/>
              <Accordion.Item onClick={()=>handleSetKey("1")} eventKey="1" className='bg-transparent border-0 shadow-0 p-0'>
                  <Accordion.Header className='bg-transparent border-0 shadow-0 p-0'>
                      <div className="d-flex justify-content-between w-100 px-2">
                          <p className="fs-20 fw-900 mb-0">2. How do I upload my pictures? </p>
                          <p className='d-flex justify-content-end'>
                          {activeKey=='1'?'-':'+'}
                          </p>
                      </div>   
                  </Accordion.Header>
                  <Accordion.Body className="text-black">
                  You can upload your pictures by creating your profile. As soon the profile is created you will see a drop down option which says "Upload Pictures" click and happy uploading.
                  </Accordion.Body>
              </Accordion.Item>
              <hr className='yellow-border-light'/>
              <Accordion.Item onClick={()=>handleSetKey("2")} eventKey="2" className='bg-transparent border-0 shadow-0 p-0'>
                  <Accordion.Header className='bg-transparent border-0 shadow-0 p-0'>
                      <div className="d-flex justify-content-between w-100 px-2">
                          <p className="fs-20 fw-900 mb-0">3. Can I vote for multiple profiles?</p>
                          <p className='d-flex active-icon justify-content-end'>
                          {activeKey=='2'?'-':'+'}
                          </p>
                      </div>
                  </Accordion.Header>
                  <Accordion.Body className="text-black">
                  Yes, you can vote for multiple profiles.
                  </Accordion.Body>
              </Accordion.Item>
          </Accordion>
      </div>
      
    )
  }
  
  export default FaqBoxComp