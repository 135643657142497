import React from 'react'
import TickIcon from '../../assets/images/tick_icon.png'
import { userNameLetter } from '../../service/utilities'
import HeaderComp from '../common/header'
import './style.scss'

const UploadBannerComp = ({userPage,userPost=true,bannerBg=false,title,children,icon,profilePic,bannerPink}) => {
  return (
    <section className={`${!bannerBg?'upload-banner-container':''} position-relative`}>
        <HeaderComp/>
        <div className='row align-items-center flex-column'>
            {icon&&
                <div className='tick-icon pt-5'>
                    <img src={TickIcon} className="w-100 "/>
                </div>
            }
            <div className='col-md-10 text-center position-relative'>
                {profilePic&&
                    <>
                        {profilePic?.u_profile_image?
                            <img src={profilePic} className="rounded-circle profile-pic" />
                            :
                            <div className='text-img-box bg-transparent'>
                                <div className='rounded-circle d-flex align-items-center justify-content-center bg-dark text-white text-profile'>
                                    {title?userNameLetter(title):''}
                                </div>            
                            </div>
                        }
                    </>
                }
                
                <h1 className='my-3'>
                    {title}
                </h1>
                    {children}
            </div>
        </div>
        {bannerPink&&<div className='bg-pink-grad'></div>}
    </section>
  )
}

export default UploadBannerComp