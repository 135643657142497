import { http } from "./http";

export default class AdminService {

    editRole(id, options) {
        return new Promise((res, rej) => {
            http.getReq(`/backend/role/edit/${id}`, options).then(response => {
                res(response);
            }).catch((err) => rej(err));
        });
    }
    
    getVendors(options) {
        return new Promise((res, rej) => {
            http.getReq(`/api-service/vendors?page=${options.page}`).then(response => {
                res(response.data);
            }).catch((err) => rej(err));
        });
    }

    getLicense(options) {
        return new Promise((res, rej) => {
            http.getReq(`/api-service/fetch?name=pure`).then(response => {
                res(response.data);
            }).catch((err) => rej(err));
        });
    }

    getSingleVendor(options) {
        return new Promise((res, rej) => {
            http.getReq(`/api-service/vendors?vendorId=${options?.id}`).then(response => {
                res(response.data);
            }).catch((err) => rej(err));
        });
    }

    getAllLeaderboards(options) {
        return new Promise((res, rej) => {
            http.getReq(`/contest/topContestants`, options).then(response => {
                res(response);
            }).catch((err) => rej(err));
        });
    }

    getContestantDetail(options) {
        return new Promise((res, rej) => {
            http.getReq(`/contest/contestant?id=${options.id}&c_id=${options.contestId}&current_u_id=${options.current_u_id}`).then(response => {
                res(response);
            }).catch((err) => rej(err));
        });
    }

    getContestantList(options) {
        return new Promise((res, rej) => {
            http.getReq(`/contest/contestantslist?pageNumber=${options.page}&c_id=${options.c_id}&name=${options.name}&sortby=desc`).then(response => {
                res(response);
            }).catch((err) => rej(err));
        });
    }

    getLicenseList(options) {
        return new Promise((res, rej) => {
            http.getReq(`/license/${options?.id}`).then(response => {
                res(response.data);
            }).catch((err) => rej(err));
        });
    }

    getContestDetails(options) {
        return new Promise((res, rej) => {
            http.getReq(`/contest/list`).then(response => {
                res(response);
            }).catch((err) => rej(err));
        });
    }

    postVote(datas) {
        return new Promise((res, rej) => {
            http.createReq(`/vote`, datas).then(response => {
                res(response);
            }).catch((err) => rej(err));
        });
    }

    getVerifyMobile(datas) {
        return new Promise((res, rej) => {
            http.createReq(`/user/verifymobile`, datas).then(response => {
                res(response);
            }).catch((err) => rej(err));
        });
    }

    getOTPVerify(datas) {
        return new Promise((res, rej) => {
            http.createReq(`/user/verifyotp`, datas).then(response => {
                res(response);
            }).catch((err) => rej(err));
        });
    }


    loginPost(datas) {
        return new Promise((res, rej) => {
            http.createReq(`/user`, datas).then(response => {
                res(response);
            }).catch((err) => rej(err));
        });
    }

    imageUploadS3(datas) {
        return new Promise((res, rej) => {
            http.createReq(`/contest/image`, datas).then(response => {
                res(response);
            }).catch((err) => rej(err));
        });
    }

    contestCheckParticipant(datas) {
        return new Promise((res, rej) => {
            http.createReq(`/contest/check/enroll`, datas).then(response => {
                res(response);
            }).catch((err) => rej(err));
        });
    }

    contestParticipantEnroll(datas) {
        return new Promise((res, rej) => {
            http.createReq(`/contest/enroll`, datas).then(response => {
                res(response);
            }).catch((err) => rej(err));
        });
    }

    contestImageUpload(options,datas) {
        return new Promise((res, rej) => {
            http.createReq(`/contest/enroll/${options.contestId}`, datas).then(response => {
                res(response);
            }).catch((err) => rej(err));
        });
    }

    deleteAdmin(datas) {
        return new Promise((res, rej) => {
            http.createReq(`/backend/admin/delete`, datas).then(response => {
                res(response);
            }).catch((err) => rej(err));
        });
    }

}