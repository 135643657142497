import React ,{useState,useEffect} from 'react'
import UploadBannerComp from '../components/UploadComp/uploadBanner'
import PrizeImg from '../assets/images/group12380.png'
import ProfilePic from '../assets/images/profthumb.png'
import pointsFrameIcon from '../assets/images/greekframe.png'
import Icon1 from '../assets/images/Frame.png'
import Icon2 from '../assets/images/Frame (1).png'
import Icon3 from '../assets/images/Frame (2).png'
import Icon4 from '../assets/images/Frame (3).png'
import EntryList from '../components/EntryComp/entryList'
import AdminService from '../service/AddCategory'
import { useParams } from 'react-router';
import SubtractThumbsUp from '../assets/images/thumbsup-subtract.png'
import SubtractStars from '../assets/images/star-subtract.png'
import './userEntry.scss'
import FooterComp from '../components/common/footer'
import Swal from 'sweetalert2';
import { setRank } from '../service/utilities'


const iconList= [{ src: Icon1, link: "http://www.facebook.com/sharer/sharer.php?u=example.org"}, { src: Icon2, link: "https://twitter.com/intent/tweet?&url=example.org" }, { src: Icon3, link: "#" }];

const EntryPage = () => {
    const [userData, setUserData] = useState({})
    const [contest, setContest] = useState({})
    const adminServe = new AdminService()
    const { id } = useParams();
    let user = JSON.parse(localStorage.getItem('userData'))
    const self = Number(user.u_id)==id;
    
    const getConestantDetailById = (c_id) =>{
        adminServe.getContestantDetail({'id':id,'contestId':c_id,current_u_id:user.u_id}).then(res => {
            setUserData(res)
        }).catch(err => {
    
        })  
    }

    const postVoteContest = (obj) =>{
        let postObj={
            // "u_id":localStorage.getItem("userData").id,
            "u_id":user.u_id,
            ...obj
        }
        adminServe.postVote(postObj).then(res => {
            Swal.fire({
                title: 'Success',
                showCancelButton: false,
                icon: 'success',
                html: 'Voted Successfully!',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Ok'
            }); 
            getContest()
        }).catch(err => {
            Swal.fire({
                title: 'Error',
                showCancelButton: false,
                icon: 'error',
                html: 'Vote Already Entered!',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Ok'
            });
        })  
    }

    const getContest = async () => {
        adminServe
          .getContestDetails({})
          .then((res) => {
             setContest(res[0]);
             getConestantDetailById(res[0].c_id);
          })
          .catch((err) => {});
    };

    useEffect(() => {
        if(contest?.c_id===undefined){
            getContest()
        }  
    }, [])
    
  return (
    <>
        <section className='banner-img-container'>
        <UploadBannerComp
            title={userData?.u_name}
            profilePic={userData}
            bannerPink={true}
            userPage={true}
        >
            <p>
                {userData?.ce_desc}
            </p>
            <div className="row justify-content-center position-relative">
                <div className='col-md-6'>
                    <div className='row align-items-start border-y p-3'>
                        <div className='col'>
                            <h5>Rank</h5>
                            <div className='px-4'>
                                <div className='d-flex align-items-center justify-content-center'>
                                    <h4 className='mb-0 px-2' style={{color:'#C98820'}}>{userData?.rank?.rank_position}</h4>
                                </div>
                            </div> 
                        </div>
                        <div className='col'>
                            <h5>Points</h5>
                            <div className='px-4'>
                                {/* <div className='d-flex justify-content-center flex-column align-items-center'>
                                        <img className='position-absolute' src={pointsFrameIcon}/>
                                        <h6 className='mb-0'>{userData?.ce_points}</h6>
                                </div> */}
                                <div className='d-flex align-items-center justify-content-center'>
                                    <img className='h-100 w-auto' src={SubtractStars}/>
                                    <h4 className='mb-0 px-2' style={{color:'#C98820'}}>{userData?.ce_points}</h4>
                                </div>
                            </div> 
                        </div>
                        <div className='col border-right-0'>
                            <h5>Votes</h5>
                            <div className='px-4'>
                                <div className='d-flex align-items-center justify-content-center'>
                                        <img className='h-100 w-auto' src={SubtractThumbsUp}/>
                                        <h4 className='px-2 mb-0' style={{color:'#C98820'}}>{userData?.ce_vote}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className='d-flex flex-column align-items-center my-3 position-relative'>
                    <p style={{color:'#882F29'}}>
                        Share Your Album:
                    </p>
                    <div className='d-flex justify-content-center'>
                        {iconList.map(x=>{
                                    return(
                                        <a className='footer-icons' href={x.link}>
                                            <span className='rounded-circle shadow-sm icon-circle rounded-circle mx-1'>
                                                <img src={x.src} className='footer-icon' />
                                            </span>
                                        </a>
                                    )
                        })}
                    </div>
            </div>
        </UploadBannerComp> 
            <div className='container position-relative'>
                <div className='d-flex justify-content-center'>
                    <div className='col-md-9'>
                        <EntryList
                         self={!self}
                         contestant_images={userData?.contestant_images} 
                         submitVote={postVoteContest}
                         />
                    </div>
                </div>
            </div>
            <FooterComp/>
        </section>
        

    </>
  )
}

export default EntryPage    