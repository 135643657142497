import React, {useState} from 'react'
import Wranglerlogo from '../../assets/images/Wranglerlogo.png'
import {Dropdown} from 'react-bootstrap';
import { useAuth, logout } from "../../service/utilities";
import DropDownIcon from '../../assets/images/dropthumb_icon.png'
import closeIcon from '../../assets/images/Union.png'
import UserIcon from '../../assets/images/user.png'

import './style.scss'

const HeaderComp = ({toggle}) => {
    const [drop1, setdrop] = useState(true)
    const [drop2, setdrop2] = useState(true)
    const user = useAuth();
    let userData = JSON.parse(localStorage.getItem('userData'))
    let page=window.location.pathname

    const handleDropbar=()=>{
        setdrop(!drop1)
        setdrop2(true)
    }
    const handleDropbar2 = () =>{
        setdrop(true)
        setdrop2(!drop2)
    }
  return (
    <>
        <section className='header d-md-block d-none d-sm-none m-0'>
            <div className='row justify-content-between align-items-center m-0'>
                <div className='col-md-4'>
                    <ul className='d-flex justify-content-around'>
                        <li className={`cursor-pointer `}><a className={`${page=='/'?'active':''}`} href='/'>Home</a></li>
                        <li className={`cursor-pointer`}><a className={`${page.includes('/entries')?'active':''}`} href='/entries'>Entries</a></li>
                        {/* <li className={`cursor-pointer`}><a className={`${page.includes('/leaderboard')?'active':''}`} href='/leaderboard'>Leaderboard</a></li> */}
                    </ul>
                </div>
                <div className="brand-logo col d-flex justify-content-center" >
                    <img src={Wranglerlogo} />
                </div>
                <div className='col-md-4'>
                    <ul className='d-flex justify-content-around'>
                        <li className={`cursor-pointer`}><a className={`${page.includes('about')?'active':''}`} href='#'>About</a></li>
                        <li className={`cursor-pointer`}><a className={`${page.includes('terms')?'active':''}`} href='/terms'>T&C</a></li>
                        {user?
                            <li className="cursor-pointer">
                                
                                <Dropdown className="bg-transparent custom-dropdown fw-bolder">
                                    <Dropdown.Toggle variant="transparent" id="dropdown-basic">
                                    <a href='#'>Profile</a>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        {userData?.u_type=='2'&&
                                            <Dropdown.Item href={`/userentry/${userData?.u_id}`}>My Profile</Dropdown.Item>
                                        }
                                        <Dropdown.Item href="/user">Contest Profile</Dropdown.Item>
                                        {/* <Dropdown.Item href="/">My Contests</Dropdown.Item> */}
                                        <Dropdown.Item onClick={()=>logout()}>Logout</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </li>
                            :<li className="cursor-pointer"><a href='#' onClick={()=>toggle()}>Login</a></li>
                        }
                    </ul>
                </div>
            </div>
        </section>
        <section className='header d-sm-block d-md-none d-lg-none m-0'>
            <div className='d-flex justify-content-between align-items-center m-0'>
                <img src={DropDownIcon} onClick={()=>handleDropbar()} className="h-100 w-auto p-3"/>
                <img src={Wranglerlogo} className="h-100 w-auto"/>
                <img src={UserIcon} onClick={()=>handleDropbar2()} className="h-100 w-auto p-3"/>
            </div>    
             <div className={`col-12 position-relative bg-white ${drop1?'d-none':''}`}>
                    {/* <div className='d-flex justify-content-end'>
                        <img src={closeIcon} alt='close icon' />
                    </div>     */}
                    <ul className='dropdown-nav w-100 d-flex flex-column justify-content-center align-items-center p-0'>
                        <li className="cursor-pointer border-bottom py-2 d-flex justify-content-center"><a className={`${page=='/'}`} href='/'>Home</a></li>
                        <li className="cursor-pointer border-bottom py-2 d-flex justify-content-center"><a className={`${page.includes('/entries')}`} href='/entries'>Entries</a></li>
                        {/* <li className="cursor-pointer border-bottom py-2 d-flex justify-content-center"><a className={`${page.includes('/leaderboard')}`} href='/leaderboard'>Leaderboard</a></li> */}
                        <li className="cursor-pointer border-bottom py-2 d-flex justify-content-center"><a className={`${page.includes('/about')}`} href='/'>About</a></li>
                        <li className="cursor-pointer border-bottom py-2 d-flex justify-content-center"><a className={`${page.includes('/terms')}`} href='/'>T&C</a></li>
                    </ul>
            </div>
            <div className={`col-12 position-relative bg-white ${drop2?'d-none':''}`}>
                    {/* <img src={closeIcon} alt='close icon' /> */}
                    <ul className='dropdown-nav w-100 d-flex flex-column justify-content-center align-items-center p-0'>
                    {user?
                        <li className="cursor-pointer border-bottom py-2 d-flex justify-content-center" onClick={()=>window.location.href=='/user'}><a href='/'>Profile</a></li>
                        :
                        <li className="cursor-pointer border-bottom py-2 d-flex justify-content-center" onClick={()=>toggle()}><a href='/'>Login</a></li>
                    }
                        <li className="cursor-pointer border-bottom py-2 d-flex justify-content-center" onClick={()=>logout()}><a href='/'>Logout</a></li>
                    </ul>
            </div>
        </section>
    </>
  )
}

export default HeaderComp