import React, {useState,useEffect} from 'react'
import FooterComp from '../components/common/footer'
import UserBannerComp from '../components/LandingComp/userBanner'
import ParticipateVerifyComp from '../components/LoginComp/participantVerifyBox'
import AdminService from '../service/AddCategory'

const UserHome2Page = () => {
  const [isOpen,setisOpen]=useState(false)
  const [maxlimit,setmaxlimit]=useState(false)
  const [contest,setContest]=useState({})
  const adminServe = new AdminService()
  const toggle=()=>{
    setisOpen(!isOpen)
  }
  
  let userData = JSON.parse(localStorage.getItem('userData'))

  const getConestantDetailById = (c_id) =>{
    adminServe.getContestantDetail({
      'id': userData?.u_id,
      'contestId': c_id,
      'current_u_id':userData?.u_id 
    }).then(res => {
      if(res?.contestant_images?.length==10){
        setmaxlimit(true)
      }
    }).catch(err => {

    })  
  }

  const getContest = async () => {
    adminServe
      .getContestDetails({})
      .then((res) => {
        setContest(res[0]);
        getConestantDetailById(res[0].c_id);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if(userData?.u_type=='2'){
      getConestantDetailById()
    }
    getContest();
  }, [])
  
  
  return (
    <>  
        <UserBannerComp toggle={toggle} maxlimit={maxlimit}/>
        <FooterComp/>
        <ParticipateVerifyComp
          toggle={toggle}
          isOpen={isOpen}
        />
    </>
  )
}

export default UserHome2Page