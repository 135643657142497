import React from 'react'
import BrownTape from '../../assets/images/browntape.png'
import ThumbsUp from '../../assets/images/bi_hand-thumbs-up.png'

import './style.scss'

const EntryList = ({contestant_images,submitVote,self}) => {
    
  return (
    <>
        <div class="container">
            <div class="masonry">
                {contestant_images?.map(entry=>{
                    return <>
                        <div className="brick">
                            <div className='card bg-transparent border-0 p-3'>
                                <div>
                                    {/* <img src="https://image.freepik.com/foto-gratuito/trama-di-pittura-ad-olio-viola_53876-94002.jpg"/> */}
                                    <img src={entry?.ci_imageUrl} />
                                </div>
                                <div className='position-relative bg-paper pb-4 px-3'>
                                    <img src={BrownTape} className="tape-img" />
                                    <i>{entry?.ci_description} </i>
                                    {self&&
                                        <button 
                                        disabled={entry?.v_id}
                                        onClick={()=>submitVote({   
                                        "participantid": entry?.ci_u_id,
                                        "imageid":entry?.ci_id,
                                        "ce_id":entry?.cl_ce_id})} 
                                        className='btn-submit px-4 w-100 d-flex align-items-center justify-content-center'
                                        >
                                        {entry?.v_id?
                                        "Voted already":
                                        <>
                                            <img src={ThumbsUp} className="mx-2 w-auto h-auto"/>
                                            Vote
                                        </>
                                        } 
                                        </button>
                                    }
                                      
                                    
                                </div>
                            </div>
                        </div>
                    </>
                })}
        </div>
      </div>  

    </>
  )
}

export default EntryList