import React from 'react'
import './style.scss'

import ImageContent1 from '../../assets/images/useredit.png'  
import ImageContent2 from '../../assets/images/bannerPhotoIcon.png'  
import ImageContent3 from '../../assets/images/Subtract.png'
import ImageContent4 from '../../assets/images/iconoir_leaderboard-star.png'  
import ImageContent5 from '../../assets/images/Vector_mountain.png' 
import ImageContent6 from '../../assets/images/Vector_edit.png' 
import ImageContent7 from '../../assets/images/Vector_media.png'
import ImageContent8 from '../../assets/images/Vector_share.png' 
const registerSteps =[{
        ImageContent:ImageContent6,
        ImageContext:'Register to create a profile.'
    },
    {
        ImageContent:ImageContent7,
        ImageContext:'Share pictures of your adventures.'
    },
    {
        ImageContent:ImageContent8,
        ImageContext:'Share your profile with friends and followers.'
    },
    {
        ImageContent:ImageContent5,
        ImageContext:'The profile with the most votes wins!'
    },
]

const registerSteps1 =[{
    ImageContent:ImageContent1,
    ImageContext:'Sign up or log in.'
},
{
    ImageContent:ImageContent2,
    ImageContext:'Look up contestants and their entries.'
},
{
    ImageContent:ImageContent3,
    ImageContext:'Cast your vote.'
},
{
    ImageContent:ImageContent4,
    ImageContext:'Check out the leaderboard for audience favorites.'
},
]

const RegistrationSteps = ({toggle,keyState}) => {
    
  return (
    <section className='container registration-box my-5 p-0'>
        <div className='row'>
            {(keyState=='home'?registerSteps:registerSteps1).map((x,i)=>{
                return(
                    <>
                        <div className='row my-3 align-items-center p-0'>
                            <div className='col-3 col-md-2'>
                                <div className='rounded-circle bg-white shadow round-box'>
                                    <img src={x.ImageContent} className="thumb-icon w-auto h-auto p-2"/>
                                </div>
                            </div>
                            <div className='col-7 col-md-10'>
                                <p className='text-black mb-0 flex-nowrap registration-txt'>{x.ImageContext}</p>    
                            </div>
                        </div>
                        {registerSteps.length!==i+1&&<hr className='yellow-border-light my-2 '/>}
                    </>
                )
            })}
            <div className='col-md-5 my-5'>
                {(keyState=='home'?
                    <button onClick={()=>toggle()} className='btn-submit border-0 px-2 w-100'>
                        Participate Now
                    </button>
                    :
                    <button onClick={()=>toggle()} className='btn-submit border-0 px-2 w-100'>
                        Vote Now
                    </button>
                )}
            </div>
        </div>
    </section>
  )
}

export default RegistrationSteps