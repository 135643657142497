import React, {useState,useEffect} from 'react'
import HeaderComp from '../common/header'
import starIcon from '../../assets/images/starclipse.png'
import profileThumbIcon from '../../assets/images/profthumb.png'
import leaderboardImg from '../../assets/images/bg_img_leaderboard.png'
import ArrowRightChevron from '../../assets/images/arrowrightchevron.png'
import AdminService from '../../service/AddCategory'
import './style.scss'

const LeaderboardBannerComp = () => {
    const adminServe = new AdminService()

    const [ranking, setRanking] = useState([])
    
    const getLeaderBoardList = () =>{
        adminServe.getAllLeaderboards().then(res => {
            setRanking(res)
        }).catch(err => {
    
        })  
    }

    useEffect(() => {
        getLeaderBoardList()
    }, [])
    
  return (
    <section className='print-bg'>
        <HeaderComp/>
        <div className='container'>
            <div className='row'>
                <div className='col-md-5'>
                    <h1 className='text-black text-center mx-auto'>Top 10 Runners</h1>
                    {ranking.map((rank,index)=>{
                        return <div className='leaderbar-card shadow row position-relative cursor-pointer' onClick={()=>window.location.href="/userentry/"+rank.u_id}>
                            <div className='diamond-square'>
                                <p className='mb-0 text-white'>{index+1}</p>
                            </div>
                            <div className='col-md-6 px-5 d-flex align-items-center'>
                                <div className=''>
                                    <img src={rank.ci_imageUrl?rank.ci_imageUrl:profileThumbIcon} className="rounded-circle profile-thumb"/>
                                </div>
                                <p className='mb-0 fw-800 px-2'>{rank.u_name}</p>
                            </div>
                            <div className='col-md-6 d-flex align-items-center justify-content-end'>
                                <img src={starIcon} className=""/>
                                <p className='mb-0 px-2'>{rank.ce_points}</p>
                                <img src={ArrowRightChevron} className=""/>
                            </div>
                            
                        </div>
                    })}
                </div>
                <div className='col-md-7'>
                    <div className='img-container p-5'>
                        <img src={leaderboardImg} className="w-100 h-auto"  />
                    </div>
                    <p>
                        Drive through the wilderness with Wrangler! Enjoy blue skies, snowy mountains and unexplored trails. 
                    </p>
                </div>
            </div>
        </div>
    </section>
  )
}

export default LeaderboardBannerComp