import React,{useState,useEffect} from 'react'
import BannerIcon2 from '../../assets/images/bannerPhotoIcon.png'
import BannerIcon3 from '../../assets/images/landscapeIcon.png'
import profileImg from '../../assets/images/profthumb.png'
import HeaderComp from '../common/header'
import AdminService from '../../service/AddCategory'
import './style.scss'
import Swal from 'sweetalert2'
import { userNameLetter } from '../../service/utilities'

const UserBannerComp = ({toggle, maxlimit}) => {
    const adminServe = new AdminService()
    let user = JSON.parse(localStorage.getItem('userData'))
    const [userData, setuserData] = useState({})

    useEffect(() => {
        if(userData?.u_id===undefined){
            let user=JSON.parse(localStorage.getItem('userData'))
            setuserData(user)    
        }
    }, [userData])
    
    const checkParticipant =()=>{
        if(maxlimit){
            Swal.fire({
                title: 'Error',
                showCancelButton: false,
                icon: 'error',
                html: "Maximum Upload Limit Reached",
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Ok'
            }); 
        }
        else{
            let formData = new FormData()
            formData.append('u_id', Number(user.u_id));
            adminServe.contestCheckParticipant(formData).then(res => {
                if(res[0].isparticipant==false){
                  toggle()
                }
                else{
                    window.location.href="/upload"
                }
              }).catch(err => {
                
              }) 
        }
    }
    
  return (
    <>
        <section className='background-fit-user-container text-center'>
                    <HeaderComp/>
                    <div className='row justify-content-center pt-3'>
                        <div className='bg-white-grad'></div>
                        <div className='p-2 col-md-6 text-center position-relative'>
                            {userData?.u_profile_image?
                                <img src={userData?.u_profile_image} className="rounded-circle profilebox" />
                            :
                                <div className='img-box bg-transparent'>
                                    <div className='rounded-circle d-flex align-items-center justify-content-center bg-dark text-white text-profile'>
                                        {userData?.u_name?userNameLetter(userData?.u_name):''}
                                    </div>            
                                </div>
                            }
                            
                            <h1 className='text-black my-3'>Welcome {user?.u_name}!</h1>
                            <h4 className='text-black banner-sub-text my-3'><span className='border-bottom-black h4'>Upload</span> to begin your journey and get a chance to win your most memorable adventure yet.</h4>
                            {/* <p className='text-black'>Share travel stories and stand a chance to win an adventure of a lifetime.</p> */}
                            <div className='row justify-content-center my-3'>
                                <div className='col-md-5 col-6'>
                                    <div className='d-flex flex-column align-items-center'>
                                        <div className='bg-white shadow rounded-circle round-box my-2'>
                                                    <img src={BannerIcon2} className="banner-icon"/>
                                        </div>
                                        <h5 className='fw-bolder'>Create an album with ten of your favourite photos.</h5>
                                    </div>
                                </div>
                                <div className='col-md-5 col-6'>
                                    <div className='d-flex flex-column align-items-center'>
                                        <div className='bg-white shadow rounded-circle round-box my-2'>
                                                    <img src={BannerIcon3} className="banner-icon"/>
                                        </div>
                                        <h5 className='fw-bolder'>The most voted profile wins an exciting Wrangler experience.</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex align-items-center justify-content-center'>
                        <button onClick={()=>checkParticipant()} className='btn-submit col-md-2 px-2 mx-2 banner-btn'>
                            Upload Photos
                        </button>
                        <button onClick={()=>window.location.href="/entries"} className='btn-submit col-md-2 px-2 mx-2 banner-btn'>
                            Vote Now
                        </button>
                    </div>
        </section>                    
    </> 
  )
}

export default UserBannerComp