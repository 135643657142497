import React from 'react'
import FooterComp from '../components/common/footer'
import HeaderComp from '../components/common/header'
import TitleBoxComp from '../components/common/titleBox'

const TermsPage = () => {
  return (
    <>
      <section className='bg-primarycolor'>
        <HeaderComp />
        <div className='row justify-content-center mb-5'>
          <TitleBoxComp title={'Terms and Conditions for Wrangler’s # Outdoor Trailblazers contest'}>
            <p className='text-start'>The terms and conditions mentioned below are applicable for the Wrangler brand’s online contest known as <b>“Outdoor Trailblazers”</b> of Ace Turtle Omni Private Limited (herein after referred to as <b>“Ace Turtle”</b>) and shall be exclusively applicable to those users and customers who purchase Wrangler t-shirts under the Terranova collections with a QR code printed on such t-shirts.</p>

            <p className='text-start'><b>Conditions and processes to be followed for participation -</b></p>
            <p className='text-start'>The Contest shall open on 1st September 2022 and shall close on 31st Dec 2022 (‘Contest Period’).</p>
            <p className='text-start'>To participate in the Outdoor Trailblazer event, the Users shall buy any Wrangler t-shirt under the Terranova collection having a QR Code.</p>
            <p className='text-start'>After that, the User shall scan the QR Code on the t-shirt upon which the User shall be directed to the contest website <a href="https://wrangler.in/fashion-tech/">https://wrangler.in/fashion-tech/</a>. The User will have to register on the website with his / her email ID upon which a confirmation email will be received by the User.</p>
            <p className='text-start'>Once the contest begins, the User will have to upload a maximum of 10 images on the website for voting by the public.</p>
            <p className='text-start'>The Users can themselves vote once per image.</p>
            <p className='text-start'>Ace Turtle will choose a minimum of five winners. The winners will be rewarded with a specially curated all-expenses paid road trip.</p>
            <p className='text-start'>The decision of Ace Turtle to select the winners of the Contest is up to the sole discretion of Ace Turtle and shall be final and binding on the participating Users.</p>
            <p className='text-start'>Ace Turtle shall not be held liable/ obligated to answer any queries for a participant’s entry or pertaining to winning or losing the Contest, or to the process of selecting the winners.</p>
            <p className='text-start'>This Outdoor Trailblazer contest event can be amended or discontinued at any given point in time, at the absolute discretion of Ace Turtle. If Ace Turtle suspends or cancels the Contest in the interim, all aspects of the Contest shall be null and void. Ace Turtle will not be liable to compensate any participating User for any postponement or cancellation or for any reason directly or indirectly arising out of this Contest.</p>
            <p className='text-start'>You undertake that you are above the age of 18 years to participate in this contest.</p>
            <p className='text-start'>In case of any dispute, the courts in Bangalore shall have exclusive jurisdiction.</p>
          </TitleBoxComp>
        </div>
          <FooterComp/>
      </section>
    </>
  )
}

export default TermsPage