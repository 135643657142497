import React, { useState, useEffect } from "react";
import cloudIcon from "../../assets/images/cloud_icon.png";
import ColImage1 from "../../assets/images/Rectangle6.png";
import ColImage2 from "../../assets/images/Rectangle4.png";
import ColImage3 from "../../assets/images/rectangle2.png";
import ColImage4 from "../../assets/images/Rectangle5.png";
import ColImage5 from "../../assets/images/Rectangle4.png";
import ColImage6 from "../../assets/images/Rectangle6.png";
import ColImage7 from "../../assets/images/Rectangle3.png";
import ColImage8 from "../../assets/images/Rectangle6.png";
import ColImage9 from "../../assets/images/Rectangle4.png";
import ColImage10 from "../../assets/images/rectangle2.png";
import CloseIcon from "../../assets/images/Union.png";
import AdminService from "../../service/AddCategory";
import useValidator from "../../service/validator";
import Swal from "sweetalert2";

import "./style.scss";
import { DragFileUpload } from "../common/dragFileUpload";

let ImgArr = [
  ColImage1,
  ColImage2,
  ColImage3,
  ColImage4,
  ColImage5,
  ColImage6,
  ColImage7,
  ColImage8,
  ColImage9,
  ColImage10,
];
const UploadFormComp = () => {
  const adminServe = new AdminService();

  const [validator, showValidationMessage] = useValidator();
  const [isloading, setisloading] = useState(false);
  const [issubmitloading, setissubmitloading] = useState(false);
  const [isupload, setUpload] = useState(false);
  const [fileUpload, setfileUpload] = useState({});
  const [imageList, setImagelist] = useState([]);
  const [contest, setContest] = useState({});
  const [description, setdescription] = useState("");

  let userData = JSON.parse(localStorage.getItem("userData"));

  const handleLogoUpload = async (e) => {
    setisloading(true);
    if((e.length + imageList.length)>10){
     Swal.fire({
          title: "Error",
          showCancelButton: false,
          icon: "error",
          html: "Maximum upload 10 Photos!",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Ok",
      })
      setisloading(false);
      return null
      }
    let formData = new FormData();
    for (let item of e) {
      if (item.size < 10000000) {
        formData.append("file[]", item);
      } else {
        setisloading(false);
        Swal.fire({
          title: "Error",
          showCancelButton: false,
          icon: "error",
          html: "Maximum Upload limit Reached!",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Ok",
        });
        break;
      }
    }

    if (imageList.length < 10) {
      await adminServe
        .imageUploadS3(formData)
        .then((res) => {
          setUpload(true);
          let imageArr = [...imageList];
          for (let item of res?.imagePath) {
            let imageObj = {
              imagePath: item,
              description: "",
            };
            imageArr.push(imageObj);
          }
          setImagelist(imageArr);
          setisloading(false);
        })
        .catch((err) => {
          setisloading(false);
        });
    } else {
      setisloading(false);
      Swal.fire({
        title: "Error",
        showCancelButton: false,
        icon: "error",
        html: "Maximum Upload limit Reached!",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Ok",
      });
    }
  };

  const handleDesc = (e, i) => {
    const { value } = e.target;
    let imageArr = imageList;
    imageArr[i].description = value;
    setImagelist([...imageArr]);
  };

  const handleSubmit = async () => {
    setissubmitloading(true);
    if (validator.allValid()) {
      await addContestParticipant();
      let imageArr=imageList.filter(item=>item.ci_c_id===undefined||item.ci_c_id === null)
      
          let obj = {
            u_id: JSON.parse(localStorage.getItem("userData")).u_id,
            contest_images:imageArr
          };

          await adminServe
            .contestImageUpload({ contestId: contest.c_id }, obj)
            .then((res) => {
              setissubmitloading(false);
              window.location.pathname = "/thanks";
            })
            .catch((err) => {
              setissubmitloading(false);
              Swal.fire({
                title: "Error",
                showCancelButton: false,
                icon: "error",
                html: err?.data?.message?.contest_images,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Ok",
              });
            });
    } else {
      setissubmitloading(false);
      showValidationMessage(true);
    }
  };

  const addContestParticipant = async () => {
    if (validator.allValid()) {
      await adminServe
        .contestParticipantEnroll({
          u_id: JSON.parse(localStorage.getItem("userData")).u_id,
          c_id: contest.c_id,
          description: description,
        })
        .then((res) => {})
        .catch((err) => {});
    } else {
      showValidationMessage(true);
    }
  };

  const handleRemoveImg = (i) => {
    let imageArr = imageList;
    imageArr.splice(i, 1);
    setImagelist([...imageArr]);
  };

  const getContest = async () => {
    adminServe
      .getContestDetails({})
      .then((res) => {
        setContest(res[0]);
        getConestantDetailById(res[0].c_id);
      })
      .catch((err) => {});
  };

  const getConestantDetailById = (c_id) => {
    adminServe
      .getContestantDetail(
        { 
          id: userData?.u_id,
          contestId: c_id,
          current_u_id:userData?.u_id 
        })
      .then((res) => {
        let imageArr = [];
        res?.contestant_images.map((item) => {
          let obj = {
            u_id: JSON.parse(localStorage.getItem("userData")).u_id,
            imagePath: item.ci_imageUrl,
            description: item.ci_description,
            ci_c_id: item.ci_c_id,
          };
          imageArr.push(obj);
        });
        setdescription(res?.ce_desc);
        setUpload(true);
        setImagelist(imageArr);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getContest();
  }, []);

  return (
    <section className="upload-form-container container p-3">
      <div className="row align-items-end">
        <div className="col-md-6 p-0 col-12">
          <div className="text-left" style={{ textAlign: "left" }}>
            <h4 className="my-3 text-black mb-0">
              What sort of traveller are you? *
            </h4>
            <p className="fw-bolder mb-0 fs-16">
              Share your passion about travel, tech and fashion
            </p>
          </div>
        </div>
        <div className="col-md-6 col-12 d-flex justify-content-end text-right">
          <p className="mb-0 text-right">25 characters</p>
        </div>
      </div>
      <div className="col-md-12">
        <textarea
          rows={2}
          value={description}
          onChange={(e) => setdescription(e.target.value)}
          placeholder="I love to travel because.."
          className="p-2 border-0 bg-white w-100 text-black"
        />
        {validator.message("Description", description, "required", {
          messages: { required: "Description is required" },
        })}
      </div>
      {/* Question 2 */}
      <div className="row align-items-end">
        <div className="col-md-6 p-0 col-12">
          <div className="text-left" style={{ textAlign: "left" }}>
            <h4 className="my-3 text-black mb-0">Upload*</h4>
            <p className="fw-bolder mb-0 fs-16 text-black">
              You can upload a maximum of 10 best journey photos
            </p>
          </div>
        </div>
        <div className="col-md-6 col-12 d-flex justify-content-sm-end justify-content-start p-0">
          <p className="fw-bolder mb-0 fs-16 border-bottom text-black">
            View Content Guidelines
          </p>
        </div>
      </div>
      <div className="col-md-12">
        <div className={`${!isupload ? "border bg-white" : ""} p-3`}>
          {!isupload ? (
            <div className="upload-box border-dashed">
              {/* Upload Box Empty */}
              <DragFileUpload
                className={` mainbg no-img w-100 d-flex justify-content-center`}
                // acceptFileFormat={['*']}
                handleFileUpload={(e) => handleLogoUpload(e)}
              >
                <div className="text-center">
                  <img src={cloudIcon} />
                  <p>Click here to upload or Drop Photo</p>
                  <p>JPEG. or PNG and 10 MB max</p>
                </div>
              </DragFileUpload>
            </div>
          ) : (
            <>
              {/* Upload Comp */}
              <div className="row align-items-end p-3">
                {imageList?.map((item, i) => {
                  return (
                    <div className="col-md-3 col-12 col-lg-3 my-2">
                      <div className="position-relative bg-white">
                        <button
                          disabled={item?.ci_c_id}
                          onClick={() => handleRemoveImg(i)}
                          className={`${
                            item?.ci_c_id ? "disabled" : ""
                          } close-icon rounded-circle border-0 shadow-0`}
                        >
                          <img src={CloseIcon} className="close-icon-img" />
                        </button>
                        <img src={item.imagePath} className="w-100" />
                      </div>
                      <textarea
                        disabled={item?.ci_c_id}
                        onChange={(e) => handleDesc(e, i)}
                        className="form-control rounded-0"
                        value={item?.description}
                        rows={4}
                        placeholder="Add a caption"
                      />
                      {/* {validator.message("TextBox", imageList[i].description, "required", {messages: {required: "Description is required"}})} */}
                    </div>
                  );
                })}
              </div>
            </>
          )}
          <div className="row justify-content-between align-items-center m-0 p-2">
            <div className="col-md-8 m-0 p-0 d-flex justify-content-start">
              <p className={isupload ? "text-white" : "text-black"}>
                Note: Add atleast 1 photo to initiate participation. You can
                upload more photos later.
              </p>
            </div>
            <div className="col-md-4">
              <div className="d-flex justify-content-end align-items-center">
                <span className="text-white mx-3">
                  {imageList?.length ? imageList.length : 0}/10 Photo
                </span>

                <DragFileUpload
                  disabled={isloading}
                  // acceptFileFormat={['*']}
                  handleFileUpload={(e) => handleLogoUpload(e)}
                >
                  <button
                    className={`btn btn-submit bg-transparent rounded-0 ${
                      isupload
                        ? "text-white border-white"
                        : "text-black border-black"
                    }`}
                  >
                    {isloading ? "Uploading..." : "Add Photo"}
                  </button>
                </DragFileUpload>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="text-center pt-3">
        <p className="text-white">
          By submitting any content for consideration, you agree to the{" "}
          <span className="fw-bolder fs-16 border-bottom text-white">
            Terms & Conditions
          </span>
        </p>
        <button
          onClick={() => handleSubmit()}
          className={`btn btn-submit ${
            imageList.length == 0 ? "btn-disabled disabled" : ""
          } border rounded-0`}
        >
          Submit
        </button>
      </div>
    </section>
  );
};

export default UploadFormComp;
