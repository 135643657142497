import React, {useState,useEffect} from 'react'
import CompetitionEntries from '../components/EntriesComp/competitionEntries'
import UploadBannerComp from '../components/UploadComp/uploadBanner'
import searchIcon from '../assets/images/searchicon.png'
import AdminService from '../service/AddCategory'
import {Form} from 'react-bootstrap'
import './entries.scss'
import FooterComp from '../components/common/footer'
const EntriesPage = () => {
    const [entries, setentries] = useState([])
    const [options, setoptions] = useState({page:1,name:''})
    const [contest,setContest]=useState({})
    const adminServe = new AdminService()
    
    const getContestants = (c_id) =>{
        adminServe.getContestantList({...options,c_id:c_id}).then(res => {
            let entriesTemp=[...entries,...res.results]
            setentries(entriesTemp)
        }).catch(err => {
    
        })  
    }

    const getContest = async () => {
        adminServe
          .getContestDetails({})
          .then((res) => {
            setContest(res[0]);
            // setoptions({...options,c_id:res[0].c_id})
            getContestants(res[0].c_id);
          })
          .catch((err) => {});
      };

    const handleSearchContestants = (search) =>{
        adminServe.getContestantList({...options,name:search}).then(res => {
            setentries(res.results)
        }).catch(err => {
    
        })  
    }

    useEffect(() => {
        if(contest?.c_id===undefined){
            getContest()
        }
        getContestants()
        
    }, [options])
    
  return (
    <>
            <UploadBannerComp
                title={'Entries'}
                bannerBg={true}
                userPage={false}
            >
                <div className='d-flex justify-content-center'>
                    <div className='col-md-7'>
                        <p className='fw-bolder'>
                            Drive through the wilderness with Wrangler! Enjoy blue skies, snowy mountains and unexplored trails. Share your adventure stories with us and get a chance to win a trip to Leh with Wrangler’s new Terra Nova collection.            
                        </p>
                    </div>
                </div>
            </UploadBannerComp>  
            <hr className='container my-4'/>
            <section className='container'>
            <div className='row align-items-center'>
                <div className='col-md-4' style={{borderRight:'0'}}>
                            <h5>Total Votes</h5>
                            <h4 className='py-2' style={{color:'#C98820'}}>{contest?.totalVotes}</h4>
                </div>
                <div className='col-md-8 row position-relative justify-content-end'>
                    <div className='col-md-8 left-inner-addon'>
                        <input type="text" onChange={(e)=>handleSearchContestants(e.target.value)} placeholder='Search something here' className='border-0 ml-2 w-100'/>
                        <img className='thumb-search-icon' src={searchIcon} />
                    </div>
                    {/* <div className="col-md-4">
                                <Form.Select aria-label="Default select example custom-select">
                                    <option>Highest Votes</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </Form.Select>
                    </div> */}
                </div>
            </div>
            </section>
            <div className="banner-img-container position-relative">
                <div className='bg-white-grad'></div>
                    <div className='position-relative' style={{zIndex:100}}>
                        <CompetitionEntries entries={entries}/>
                    </div>
                {/* <div className='bg-dark-grad'></div>  */}
                <div className='d-flex justify-content-center py-4'>
                    <button onClick={()=>setoptions({...options,page:options.page+1})} className="btn-submit px-4 text-white border-0 rounded-0">
                            Load More Entries
                    </button>    
                </div>
            </div>
            
            <FooterComp/> 
        
    </>
  )
}

export default EntriesPage