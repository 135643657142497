import React from 'react'
import TickIcon from '../../assets/images/tick_icon.png'
import HeaderComp from '../common/header'
import './style.scss'

const UploadFormBannerComp = ({title,children,icon,profilePic}) => {
  return (
    <section className='upload-form-banner-container'>
        <HeaderComp/>
        <div className='row align-items-center flex-column'>
            {icon&&
                <div className='tick-icon pt-5'>
                    <img src={TickIcon} className="w-100 "/>
                </div>
            }   
            <div className='col-md-10 text-center'>
                <img src={profilePic} className="h-100 w-auto rounded-circle profile-thumb" />
                <h1 className='my-3'>
                    {title}
                </h1>
                    {children}
            </div>
        </div>
    </section>
  )
}

export default UploadFormBannerComp