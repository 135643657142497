import axios from "axios"

export const axiosInstance = axios.create({
  // baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
  }
});

export const logout = () => {
//   axiosInstance.defaults.headers.common['Authorization'] = ''
  localStorage.clear()
  window.location.assign('/');

  // history.push('/')
  // window.location.assign('http://doodlebluelive.com:2238/auth/login');

  // window.location.reload(true)
}

export const useAuth = () => {
    return localStorage.getItem("authToken")?true:false
}

export const userNameLetter = (data) => {
  let name=data.split(' ')
  if(name.length>1){
    return name[0].charAt(0) + name[name.length - 1].charAt(0)
  }
  return name[0].charAt(0)
}

export const setRank = (data) => {
  
  switch (data) {
    case 1:  return "st";
    case 2:  return "nd";
    case 3:  return "rd";
    default: return "th";
  }
}


