import React from 'react'
import VoteListComp from '../UserDashboard/voteList'

const CompetitionEntries = ({entries}) => {
    let newArray=Array.from(Array(10).keys())
  return (
    <>
    <section className='container'>
        <div className='row px-md-5'>
            {entries.map(entry=>{
                return(
                    <div className='col-md-4 my-4'>
                        <VoteListComp entry={entry}/>
                    </div>    
                )
            })}
        </div>
    </section>
    </>
  )
}

export default CompetitionEntries