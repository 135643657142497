import React,{ useState,useEffect } from 'react'
import BannerComp from '../components/LandingComp/banner'
import PictureBox from '../components/LandingComp/pictureBox'
import ThumbImg1 from '../assets/images/image02.png'
import ThumbImg2 from '../assets/images/image03.png'
import RegistrationSteps from '../components/LandingComp/registrationSteps'
import MailBox from '../components/LandingComp/mailBox'
import FooterComp from '../components/common/footer'
import HeaderComp from '../components/common/header'
import PrizeListComp from '../components/LandingComp/prizeList'
import FaqBoxComp from '../components/LandingComp/faqBox'
import SinglePictureBoxComp from '../components/LandingComp/singlePictureBox'
import ModalPopComp from '../components/common/modalPop'
import LoginBox from '../components/LoginComp/loginBox'
import './home.scss'
import TabBoxComp from '../components/LandingComp/tabBox'
import TitleBoxComp from '../components/common/titleBox'
import AdminService from '../service/AddCategory'
import { useAuth } from "../service/utilities";

const HomePage = () => {
  const user = useAuth();

  const [isOpen,setisOpen]=useState(false)

  const adminServe= new AdminService()

  const toggle=()=>{
    if(user){
      window.location.href="/user"
    }
    else{
      setisOpen(!isOpen)
    }
  }
  
  return (
    <>
      <section className='bg-primarycolor'>
          <HeaderComp toggle={toggle} />
          <BannerComp toggle={toggle} />
          <section className='price-list-container container'>
            <div className='row justify-content-center mb-5'>
                <TitleBoxComp title={'Winner takes it all'}>
                    There are ways to explore the unknown within and outside. Nothing does it better than a road trip. And we, at Wrangler, are pulling out the stops with a specially curated one-of-a-kind journey for you. Camp and sleep under a sky full of stars, roast marshmallows over a bonfire, strum the guitar on a still moonlit night, or bask with elements in lush green landscapes – this freedom is yours for the taking.
                    <div className='d-flex justify-content-center align-items-center my-3'>
                      <div className='col-md-4 col-12'>
                          <button onClick={()=>toggle()} className='px-4 mb-0 btn-submit w-100'>
                              Participate Now
                          </button>
                      </div>
                  </div> 
                </TitleBoxComp>
            </div>
          </section>
          {/* <PrizeListComp/> */}
          <TabBoxComp toggle={toggle}/>
          <FaqBoxComp />
          <SinglePictureBoxComp toggle={toggle}/>
          <FooterComp/>
      </section>
      <LoginBox 
        isOpen={isOpen} 
        toggle={toggle}
      >
      </LoginBox>
    </>
  )
}

export default HomePage