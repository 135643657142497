import React from 'react'

const TitleBoxComp = ({title,children}) => {
  return (
    <div className='col-md-8 text-sm-left text-md-center pt-5 pr-5'>
                <h1 className='text-black'>{title}</h1>
                <div className='d-flex justify-content-md-center justify-content-sm-start'>
                    <div className='col-md-4 col-4 my-3'>
                        <hr className='yellow-border'/>
                    </div>
                </div>
                <p className='text-black'>
                    {children}
                </p>
    </div>
  )
}

export default TitleBoxComp