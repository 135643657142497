import React, {useEffect} from 'react'
import ModalPopComp from '../common/modalPop'
import closeIcon from '../../assets/images/closeIcon.png'
import { GoogleLogin, useGoogleLogin } from "react-google-login";
import { gapi } from 'gapi-script';
import AdminService from '../../service/AddCategory';
import fIcon from '../../assets/images/facebookicon.png'
import gIcon from '../../assets/images/googlefill.png'
import './style.scss'
import OtpBoxComp from './otpBox'

const clientId = '432872843733-3jpkh1d701dhd1sps9ivn7nvum4l3veo.apps.googleusercontent.com';


const LoginBox = ({isOpen,toggle,title,children}) => {
  const adminServe = new AdminService()

  useEffect(() => {
    const initClient = () => {
          gapi.client.init({
          clientId: clientId,
          scope: ''
        });
     };
     gapi.load('client:auth2', initClient);
 });

  return (
    <>
        <ModalPopComp 
          isOpen={isOpen}
          size="xl"
          headerClass="border-0 pt-4 px-4"
          toggle={toggle}>
            <div className='login-container login-mob-container'>
                <div className='d-flex justify-content-end login-close-icon' onClick={()=>toggle()}>
                    <img src={closeIcon}  alt="Close Icon"/>
                </div>
                <div className='col-md-5 p-3'>
                        <h1 className='text-black whitespace-nowrap'>Register or Login </h1>
                        <p className='f-18 fw-bolder'>Exciting adventures are just around the corner.</p>
                      {/* <button className='btn-submit col-8 col-md-10 text-black border-black justify-content-md-start justify-content-sm-center bg-transparent my-2 px-4 d-flex align-items-center rounded-0'> 
                            <img src={fIcon} className="" /><p className='mb-0 pl-2'>Sign in with Facebook</p>
                        </button> */}
                        {/* <button className='btn-submit col-8 col-md-10 google-btn text-black border-black justify-content-md-start justify-content-sm-center bg-transparent my-2 px-4 d-flex align-items-center rounded-0'> 
                            <img src={gIcon} className="" /><p className='mb-0 pl-2'>Sign in with Google</p>
                            <GoogleLogin
                              clientId={clientId}
                              buttonText="Login"
                              onSuccess={()=>onSuccess}
                              onFailure={onFailure}
                              cookiePolicy={"single_host_origin"}
                              isSignedIn={true}
                            />
                        </button> */}
                        <>
                          {<LoginHooks adminServe={adminServe} />}
                        </>
                        {/* <OtpBoxComp/> */}
                </div> 
            </div>
            
        </ModalPopComp> 
    </>
  )
}

function LoginHooks({adminServe}){

  const onSuccess = (res) => {
    console.log('success:', res);
    console.log(res.profileObj.name,"CHECK VALUE")
    if(res.profileObj.name){
      let obj={
        "name":res?.profileObj?.name,
        "email":res?.profileObj?.email,
        "phone":null,
        "sso_platform":1,
        "profile_image":res?.profileObj?.imageUrl
      }
      localStorage.setItem("authToken", res.tokenId);
      handleLogin(obj)
    }
  };

  const handleLogin = (data) =>{
    adminServe.loginPost(data).then(res => {
      if(res.length){
        localStorage.setItem("userData", JSON.stringify(res[0]));
        window.location.href="/user"
      }
    }).catch(err => {
      
    })  
  }

  const onFailure = (err) => {
      console.log('failed:', err);
  };

  const {signIn} =useGoogleLogin({
    onSuccess,
    onFailure,
    clientId,
    isSignedIn:false,
    accessType:'offline'
  })
  return <button onClick={signIn} className='btn-submit col-8 col-md-10 google-btn text-black border-black justify-content-md-start justify-content-sm-center bg-transparent my-2 px-4 d-flex align-items-center rounded-0'> 
  <img src={gIcon} className="" /><p className='mb-0 mx-1 fw-bolder'>Sign in with Google</p>
</button>
}

export default LoginBox