import React, { useState,useEffect } from 'react'
import HeaderComp from '../components/common/header'
import ImageStamp from '../assets/images/image_stamp.png'
import FooterComp from '../components/common/footer'
import AdminService from "../service/AddCategory";
import Icon1 from '../assets/images/Frame.png'
import Icon2 from '../assets/images/Frame (1).png'
import Icon3 from '../assets/images/Frame (2).png'
import Icon4 from '../assets/images/Frame (3).png'

//Styles
import './uploadThank.scss'

const iconList = [{ src: Icon1, link: "http://www.facebook.com/sharer/sharer.php?u=example.org"}, { src: Icon2, link: "https://twitter.com/intent/tweet?&url=example.org" }, { src: Icon3, link: "#" }];



const UploadThankPage = () => {
    const [imageBox, setImageBox] = useState({});
    const [contest, setContest] = useState({});
    const adminServe = new AdminService();
    
    let user = JSON.parse(localStorage.getItem('userData'))

    useEffect(() => {
        getContest()    
    }, [])

    const getContest = async () => {
        adminServe
          .getContestDetails({})
          .then((res) => {
            setContest(res[0]);
            getConestantDetailById(res[0].c_id);
          })
          .catch((err) => {});
    };

    const getConestantDetailById = (c_id) => {
        adminServe
          .getContestantDetail(
            { 
              id: user?.u_id,
              contestId: c_id,
              current_u_id:user?.u_id 
            })
          .then((res) => {
            setImageBox(res?.contestant_images[res?.contestant_images.length-1])
          })
          .catch((err) => {});
      };

    
  return (
    <>  
        <section className='print-bg'>
            <HeaderComp/>
            <div className='container p-5'>
                <div className='bg-thankimage-container'>
                    <div className='row align-items-center'>
                        <div className='col-12 col-md-6 m-0 p-5'>
                            <img src={imageBox?.ci_imageUrl} className="w-100 h-auto" />
                        </div>
                        <div className='col-12 col-md-6 m-0 p-0'>
                            <div className='text-left col-md-8'>
                                <h2>Congratulations on your entry!</h2>
                                <p className='fs-18 fw-bolder'>You are one step closer to Wrangler’s most exciting adventure!</p>
                                <div className='col-md-6'>
                                    <button className='btn-submit w-100 px-2' onClick={()=>window.location.href=`/userentry/${user.u_id}`}>View Your Entry</button>
                                </div>
                                
                                <div className='mt-5 share-box'>
                                    <p className='fw-bolder' style={{color:'#882F29'}}>Share Your Album:</p>
                                    <div className='col-12 col-md my-3 align-items-center icon-list d-flex'>
                                        {iconList.map(x=>{
                                            return(
                                                <a className='footer-icons' href={x.link}>
                                                    <span className='icon-circle rounded-circle' style={{marginRight:'0.8rem'}}>
                                                        <img src={x.src} className='footer-icon' />
                                                    </span>
                                                </a>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FooterComp/>
        </section>
    </>
  )
}

export default UploadThankPage