import React from 'react'

import Background from '../../assets/images/mask_group.png'
import './style.scss'
import ImageContent1 from '../../assets/images/user-edit.png'  
import ImageContent2 from '../../assets/images/Group 12453.png'  
import ArrowLeft from '../../assets/images/VectorL.png'  
import ArrowRight from '../../assets/images/VectorR.png'  
import ArrowBlackRight from '../../assets/images/vector_8.png'  
import BannerIcon1 from '../../assets/images/useredit.png'
import BannerIcon2 from '../../assets/images/bannerPhotoIcon.png'
import BannerIcon3 from '../../assets/images/landscapeIcon.png'
import FloatingImg from '../../assets/images/Mask_group_6.png'  
import MasonryComponent from './masonry'
import VoteListComp from '../UserDashboard/voteList'
// import LeadershipBoardComp from '../UserDashboard/leadershipBoard'
export default function BannerComp({user,toggle,contestName}){
  return (
    <>
        {
        user?<User toggle={toggle}/>:<Guest toggle={toggle} contestName={contestName}/> 
        }  
    </>
  )
}

const Guest=({toggle,contestName})=>{
    return <div className='background-fit-container text-center'>
                <div className='row justify-content-center pt-5'>
                    <div className='bg-white-grad'></div>
                    <div className='p-2 col-md-8 text-center position-relative media-box-100'>
                        <div className='partition-1'>
                            <h1 className='text-black my-4'>{'Outdoor Trailblazer'}</h1>
                        </div>
                        <div className='partition-2 my-4'>
                            <p className='text-black fw-bolder'>Share travel stories and stand a chance to win an adventure of a lifetime.</p>
                        </div>
                        <a href='#' className='my-4 text-black text-decoration-none' style={{borderBottom:'1px solid #000'}}>Learn More</a>
                        <div className='partition-3 row justify-content-center my-4'>
                            <div className='col-md-9'>
                                <div className='row banner-fit-div d-flex justify-content-between my-4 align-items-start my-4'>
                                    <div className='col-md-3 col-3 d-flex flex-column align-items-center'>
                                        <div className='bg-white shadow rounded-circle round-box'>
                                            <img src={BannerIcon1} className="banner-icon"/>
                                        </div>
                                        <h5 className="mt-2 fw-bolder">Register Yourself</h5>
                                    </div>
                                    <div className='col-1 mt-5'>
                                        <img src={ArrowBlackRight} className="arrow-btn" />
                                    </div>
                                    <div className='col-md-3 col-3 d-flex flex-column align-items-center'>
                                        <div className='bg-white shadow rounded-circle round-box'>
                                            <img src={BannerIcon2} className="banner-icon"/>
                                        </div>
                                        <h5 className="mt-2 fw-bolder">Upload your photographs</h5>
                                    </div>
                                    <div className='col-1 mt-5'>
                                        <img src={ArrowBlackRight} className="arrow-btn" />
                                    </div>
                                    <div className='col-md-3 col-3 d-flex flex-column align-items-center'>
                                        <div className='bg-white shadow rounded-circle round-box'>
                                            <img src={BannerIcon3} className="banner-icon"/>
                                        </div>
                                        <h5 className="mt-2 fw-bolder">Win a trip of your lifetime</h5>
                                    </div>
                                </div>
                                {/* <InputEmailBox/> */}
                                <div className="d-flex align-items-center justify-content-center my-5">
                                    <div className='col-md-4 mx-2'>
                                        <button className='px-2 col-md-4 mx-2 w-100 btn-submit' onClick={()=>toggle()}>
                                            Participate Now
                                        </button>
                                    </div>
                                    <div className='col-md-4'>
                                        <button className='px-2 col-md-4 w-100 mx-2 btn-submit' onClick={()=>toggle()}>
                                            Vote Now
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    {/* <MasonryComponent/>    */}
                    <div className='col-md-10 d-flex justify-content-center position-relative' style={{zIndex:'1000'}}>
                        <img src={FloatingImg} className="w-100 h-auto p-0 m-0"/>
                    </div>
                    <div className='bg-print-grad'></div> 
                </div>
                
            </div>
}

const User=({toggle})=>{
    let newArray=Array.from(Array(10).keys())
    return <div className='background-fit-container2 text-center'>
                <div className='bg-image-mountain'></div>
                <div className='row justify-content-center pt-5'>
                    <div className='p-2 col-md-6 text-center'>
                        <h1 className='text-black'>Welcome Ankit!</h1>
                        <h4 className='text-black banner-sub-text'><span className='yellow-border-bottom'>Start uploading</span> your #truewanderer journey and 
get a chance to win a trip to Leh Ladakh*</h4>
                        <div className='row my-3 justify-content-center'>
                            <div className='col-8 d-flex align-items-center justify-content-center'>
                                <img src={ImageContent1} className="thumb-icon"/><p className='text-black mb-0'>Upload Your Journey’s Top 10 Photos</p>    
                            </div>
                        </div>
                        <div className='row my-3 justify-content-center'>
                            <div className='col-8 d-flex align-items-center justify-content-center'>
                                <img src={ImageContent1} className="thumb-icon"/><p className='text-black mb-0'>Profile With Highest Points Win A Trip To Leh-Ladakh*</p>    
                            </div>
                        </div>
                        <div className='row justify-content-center'>
                            <div className='col-md-9 d-flex align-items-center justify-content-center'>
                                <button className='px-2 btn-submit'>
                                    Upload Photos
                                </button>
                                <p className='text-black mx-2 mb-0'>
                                    Deadline: 30th September, 2022
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sub-background-container" style={{position:'relative',zIndex:'100'}}>
                            <div className='bg-white-grad'>
                            </div>
                            <div className='vote-content-box' style={{position:'relative',zIndex:'100'}}>
                                <h3 className='text-black' >Vote from #truewanderer latest entries</h3>
                                <div className='vote-list'>
                                    {newArray.map(x=>{
                                        return <VoteListComp/>
                                    })}
                                </div>
                                <div className='d-flex justify-content-between my-4 container'>
                                    <div></div>
                                    <button className="btn-submit bg-transparent px-4 border rounded-0">
                                        View All Entries
                                    </button>
                                    <div className='d-flex justify-content-center'>
                                        <div className='arrow-button rounded-circle mx-2'>
                                            <img src={ArrowLeft} className=""/>
                                        </div>
                                        <div className='arrow-button rounded-circle'>
                                            <img src={ArrowRight} className=""/>
                                        </div>
                                    </div>
                                </div>
                            </div> 
                            <div className='bg-dark-grad'>
                            </div> 
                </div>
            </div>
}