import React , { useState } from 'react'
import {Tab,Tabs} from 'react-bootstrap';
import PictureBox from './pictureBox'
import ThumbImg1 from '../../assets/images/image03.png'
import ThumbImg2 from '../../assets/images/image02.png'
import RegistrationSteps from './registrationSteps';

const TabBoxComp = ({toggle}) => {
    const [key, setKey] = useState('home');

  return (
    <>
        <div className='container'>
            <div className='row border-dummy'>
                <div className='col-6 p-0'>
                    <button onClick={()=>setKey("home")} className={`w-100 h-auto bg-transparent font-futura-bold btn-tabs ${key=='home'?'active':''}`}>
                        <h1>Upload</h1>
                    </button>
                </div>
                <div className='col-6 p-0'>
                    <button onClick={()=>setKey("profile")} className={`w-100 h-auto bg-transparent btn-tabs font-futura-bold ${key=='profile'?'active':''}`}>
                        <h1>Vote</h1>
                    </button>
                </div>
            </div>
        </div>
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          className="mb-3"
        >
            <Tab eventKey="home" title="Home">
                <PictureBox
                    title={'How to Participate'}
                    reverse={false}
                    thumbImage={ThumbImg1}
                >
                    <RegistrationSteps keyState={key} toggle={toggle}/>
                </PictureBox>
            </Tab>
            <Tab eventKey="profile" title="Profile">
                <PictureBox
                    title={'How to Participate'}
                    reverse={true}
                    thumbImage={ThumbImg2}
                >
                    <RegistrationSteps keyState={key} toggle={toggle}/>
                </PictureBox>
            </Tab>
        </Tabs>
    </>
  )
}

export default TabBoxComp