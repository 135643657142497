import React, {useState} from 'react'
import ModalPopComp from '../common/modalPop'
import closeIcon from '../../assets/images/closeIcon.png'
import './style.scss'
import OtpBoxComp from './otpBox'
import AdminService from '../../service/AddCategory'

const ParticipateVerifyComp = ({toggle,isOpen}) => {
  const adminServe = new AdminService()
  let user=JSON.parse(localStorage.getItem('userData'))
  const [verifyState, setverifyState] = useState(true)

  const handleSubmit = () =>{
    adminServe.getVerifyMobile({u_id:user?.u_id,u_phone:user?.u_email}).then(res => {
      if(res){
        setverifyState(!verifyState)   

      }
    }).catch(err => {
      
    })  
  }

  const handleVerifyOTP = (data) =>{
    adminServe.getOTPVerify({u_id:user?.u_id,otp:data}).then(res => {
      if(res){
        window.location.href="/upload"
      }
    }).catch(err => {
      
    })  
  }

  return (
    <>
    <ModalPopComp 
    isOpen={isOpen}
    size="xl"
    headerClass="border-0 pt-4 px-4"
    toggle={toggle}>
      <div className='login-otp-container login-otp-mob-container'>
          <div className='d-flex justify-content-end login-close-icon' onClick={()=>toggle()}>
              <img src={closeIcon}  alt="Close Icon"/>
          </div>
          <div className='col-md-6 p-3'>
            {verifyState?
            <>
                <h2 className='text-black'>Email Verification</h2>
                <div className='row m-0'>
                  <div className='col-md-10 p-0'>
                    <p>Enter email to recieve a OTP</p>  
                  </div>
                </div>
                <div className='d-flex align-items-center'>
                  <input className='form-control rounded-0' disabled placeholder='Enter Email' value={user?.u_email} />
                  <button onClick={()=>handleSubmit()} className='btn-submit mx-2 text-white'>
                    Send OTP
                  </button>
                </div>
            </>:
            <>
                <h2 className='text-black'>Email Verification</h2>
                <div className='row m-0'>
                  <div className='col-md-8 col-12 p-0'>
                    <p className='mb-0'>Email OTP sent on {user?.u_email}</p>  
                  </div>
                  <div className='col-md-4 col-12 d-flex justify-content-end p-0'>
                    <a onClick={()=>handleSubmit()} className='' style={{color:'#882F29'}}>Resend OTP</a>
                  </div>
                </div>
                <OtpBoxComp handleVerifyOTP={handleVerifyOTP}/>
            </>
            }
          </div> 
      </div>
      
  </ModalPopComp>
    </>
  )
}

export default ParticipateVerifyComp
