import React from 'react'
import FooterComp from '../components/common/footer'
import LeaderboardBannerComp from '../components/LandingComp/leaderboardBanner'

const LeaderboardPage = () => {
  return (
    <>
        <section className=''>
            <LeaderboardBannerComp/>
            <FooterComp/>
        </section>
    </>
  )
}

export default LeaderboardPage