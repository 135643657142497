import React from 'react'
import Modal from 'react-bootstrap/Modal';

const ModalPopComp = ({title,children,headerClass,toggle,isOpen,bodyClass,footer}) => {
  return (
    <Modal
    show={isOpen} onHide={toggle}
    className={headerClass + `d-flex align-items-center ht-3`}
    closeButton
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {title&&
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
            {title}
            </Modal.Title>
        </Modal.Header>
      }
      <Modal.Body
        className={` bg-transparent border-0 p-0 ${ bodyClass}`}
       >
        {children}
      </Modal.Body>
      {footer&&
        <Modal.Footer>
            <button className='btn-submit' onClick={toggle}>Close</button>
        </Modal.Footer>
      }
      
    </Modal>
  );
}

export default ModalPopComp