import React from 'react'
import Icon1 from '../../assets/images/Frame.png'
import Icon2 from '../../assets/images/Frame (1).png'
import Icon3 from '../../assets/images/Frame (2).png'
import Icon4 from '../../assets/images/Frame (3).png'
import Wranglerlogo from '../../assets/images/WranglerLogoTop.png'
//SCSS
import './style.scss'

const iconList = [{ src: Icon1, link: "https://www.facebook.com/wranglerdenims"}, { src: Icon2, link: "https://twitter.com/wranglerdenims" }, { src: Icon3, link: "https://www.instagram.com/wranglerdenims" }, { src: Icon4, link: "https://www.youtube.com/c/WranglerDenimsIndia" }];

const FooterComp = ({ }) => {
    return (
        <section className=' m-0 p-0 bg-black'>
            <div className='container'>
                <div className='row align-items-center text-sm-center justify-content-sm-center'>
                    <div className='col-12 col-md my-3 text-center'>
                        <img src={Wranglerlogo} className='brand-logo-footer' />
                    </div>
                    <div className='col-12 col-md my-3 text-center'>
                        <a href='/terms' className='text-white mb-0'>Terms & Conditions</a>
                    </div>
                    <div className='col-12 col-md my-3 text-center'>
                        <a href='#' className='text-white mb-0'>Privacy Policy</a>
                    </div>
                    <div className='col-12 col-md my-3 text-center'>
                        <a href='#' className='text-white mb-0'>Disclaimers</a>
                    </div>
                    <div className='col-12 col-md my-3 text-center justify-content-center d-flex'>
                        {iconList.map(x => {
                            return (
                                <a className='footer-icons' href={x.link}>
                                    <span className='icon-circle rounded-circle mx-1'>
                                        <img src={x.src} className='footer-icon' />
                                    </span>
                                </a>
                            )
                        })}
                    </div>
                </div>
                <hr className='border-white opacity-1' />
                <p className='text-center text-white mb-0 py-3'>© 2022 Wrangler All Right Reserved</p>
            </div>
        </section>
    )
}

export default FooterComp