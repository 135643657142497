import './App.css';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useRoutes,
  Navigate
} from 'react-router-dom';
import HomePage from './pages/home';
import UploadPage from './pages/uploadPage';
import UploadThankPage from './pages/uploadThankPage';
import EntryPage from './pages/userEntryPage';
import EntriesPage from './pages/entriesPage';
import UserHome2Page from './pages/userHome2';
import LeaderboardPage from './pages/leaderboard';

//CSS
import 'bootstrap/dist/css/bootstrap.css';

//Fonts
import './assets/scss/common.scss'

import UserHomePage from './pages/userHome';

import { createBrowserHistory } from "history";

import { useAuth } from "./service/utilities";
import { useEffect } from 'react';
import TermsPage from './pages/terms';


function AppRoutes() {
  const baseURL= process.env.PUBLIC_URL
  console.log(baseURL,"CJEC")
  const routes = useRoutes(
    [
      {path: baseURL+'/',element:<HomePage/>},
      // {path: baseURL+'/user',element:<UserHomePage/>},
      {path: baseURL+'/user',element:<UserHome2Page/>},
      {path: baseURL+'/upload',element:<UploadPage/>},
      {path: baseURL+'/thanks',element:<UploadThankPage/>},
      {path: baseURL+'/userentry/:id',element:<EntryPage/>},
      {path: baseURL+'/entries',element:<EntriesPage/>},
      {path: baseURL+'/leaderboard',element:<LeaderboardPage/>},
      {path: baseURL+'/terms',element:<TermsPage/>}
    ]
  )
  return routes;
}
function App(){
  const user = useAuth();
  
  let page = window.location.pathname
  
  useEffect(()=>{
    if (user==false&&(page.includes('user')||page.includes('leaderboard')||page.includes('userentry')||page.includes('entries'))){
      // user is not authenticated
      return window.location.href="/"
    }
  },[user])
  return (
    <Router>
      <AppRoutes />
    </Router>
  )
}

export default App;

