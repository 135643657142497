import React from 'react'
import TitleBoxComp from '../common/titleBox'
import './style.scss'

const PictureBox = ({ title, children, reverse,thumbImage}) => {
  return (
    <section className='picturebox-container container p-3'>
        <div className={`row align-items-center ${reverse&&'flex-row-reverse'}`}>
            <div className='col-md-6 text-center'>
                <img src={thumbImage} className="w-md-100 w-sm-100 h-md-auto p-3 card-box-img"/>
            </div>
            <div className='col-md-6 pr-5'>
                <p className='text-black'>
                    {children}
                </p>
            </div>
        </div>
    </section>
  )
}

export default PictureBox