import React from 'react'
import userPostImg from '../../assets/images/userpost.png'
import pointsFrameIcon from '../../assets/images/greekframe.png'
import starIcon from '../../assets/images/Subtractentry.png'
import ThumbsUp from '../../assets/images/bi_hand-thumbs-up.png'
import './style.scss'
import { useBootstrapBreakpoints } from 'react-bootstrap/esm/ThemeProvider'
import { userNameLetter } from '../../service/utilities'

const VoteListComp = ({entry,userPost=true}) => {
  return (
    <>
            <div className='vote-fixed-width'>
                <div className='card card-bgimg rounded-0'>
                    {entry?.u_profile_image?
                        <img className='w-auto box-img' src={entry?.u_profile_image} />:
                        <div className='img-box'>
                            <div className='rounded-circle d-flex align-items-center justify-content-center text-profile'>
                                {userNameLetter(entry?.u_name)}
                            </div>            
                        </div>
                    }
                    <div className='p-3'>
                        <div className='d-flex justify-content-between mt-2'>
                            <div>
                                <h5 className='text-ellipsis fw-bolder'>
                                    @{entry?.u_name}
                                </h5>
                                {/* <p className='text-ellipsis mb-0'>{entry?.ce_desc}</p> */}
                            </div>
                            <div className='position-relative'>
                                {/* <div className='d-flex justify-content-center flex-column align-items-center'>
                                    <img className='position-absolute' src={pointsFrameIcon}/>
                                    <h6 className='mb-0'>20</h6>
                                </div> */}
                                <div className='d-flex align-items-center'>
                                    <img src={starIcon} style={{width:'auto',height:'auto'}}/>
                                    <div className='text-center point-box p-2'>
                                        <h5 className='mb-0'>{entry?.ce_points}</h5>
                                        <p className='mb-0 text-grey'>Points</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr/>
                        <button onClick={()=>window.location.href="/userentry/"+entry?.u_id} className='btn-submit d-flex justify-content-center align-items-center w-100'>
                            <img src={ThumbsUp} className="mx-2 w-auto h-auto"/>
                            View Profile To Vote
                        </button>
                    </div>
                </div>
            </div>
    </>
  )
}

export default VoteListComp