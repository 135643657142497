import React,{ useState, createRef, useEffect } from 'react'
import './style.scss'

const OtpBoxComp = ({handleVerifyOTP}) => {
    const [otpbox, setOtpbox]=useState('')
    let digitValidate = function(ele){
      ele.target.value = ele.target.value.replace(/[^0-9]/g,'');
    }
    
    let tabChange = function(val){
        let ele = document.querySelectorAll('input');
        let eleArr = [...document.querySelectorAll('input')];
        let str=''
        eleArr.map(x=> str += x.value)
        setOtpbox(str)
        if(ele[val-1].value != ''){
          ele[val].focus()
        }else if(ele[val-1].value == ''){
          ele[val-2].focus()
        }   
     }
    
   

  return (
    <>
          <form action="" class="mt-2 otp-box">
            <input class="otp" type="text" onInput={(e)=>digitValidate(e)}  onKeyUp={()=>tabChange(1)} maxlength='1' />
            <input class="otp" type="text" onInput={(e)=>digitValidate(e)}  onKeyUp={()=>tabChange(2)} maxlength='1' />
            <input class="otp" type="text" onInput={(e)=>digitValidate(e)}  onKeyUp={()=>tabChange(3)} maxlength='1' />
            <input class="otp" type="text" onInput={(e)=>digitValidate(e)} onKeyUp={()=>tabChange(4)} maxlength='1' />
            <input class="otp" type="text" onInput={(e)=>digitValidate(e)} onKeyUp={()=>tabChange(5)} maxlength='1' />
            <input class="otp" type="text" onInput={(e)=>digitValidate(e)} onKeyUp={()=>tabChange(5)} maxlength='1' />
          </form>
          <button onClick={()=>handleVerifyOTP(otpbox)} className='btn-submit text-white'>
                  Enter
          </button>
    </>
  )
}

export default OtpBoxComp