import axios from "axios";
// import siteConfig from '@iso/config/site.config';
import Swal from 'sweetalert2';
import { createBrowserHistory } from 'history';
import { logout } from './utilities';

const history = createBrowserHistory();
// import { toast } from 'react-toastify';

export const API = axios.create({ baseURL: 'https://wranglerapi.noesis.dev' });
const headers = {
    // 'Content-Type': 'application/json/text',
    // 'Authorization':  localStorage.getItem("authToken")
    //         ?`${localStorage.getItem("authToken")}`
    //         : "",
    // 'test': 'I\'m only test header'
}
API.interceptors.request.use(config => {
    //  Generate cancel token source
    let source = axios.CancelToken.source();
    // Set cancel token on 'this ENTERS HERE' request
    config.cancelToken = source.token;
    config.headers = headers;
    return config;
}, error => {
    return Promise.reject(error);
});

API.interceptors.response.use(response => {
    if (response.status === 200 || response.status === 201) {
        return Promise.resolve(response.data);
    } else {
        return Promise.reject(response);
    }
}, async (error) => {
    console.log(error.response,"CHECK ERROR")
    if (error.response.status) {
        switch (error.response.status) {
            case 400:
                Swal.fire({
                    title: 'Error',
                    showCancelButton: false,
                    icon: 'error',
                    html: error.response.data.error,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'Ok'
                });
                break;
            case 404:
                break;
            case 401:
                Swal.fire({
                    title: 'Error',
                    showCancelButton: false,
                    icon: 'error',
                    html: "Unauthorized, Return back to login.",
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'Ok'
                }).then((result) => {
                    logout();
                    window.location.href="/"
                });
                break;
            case 403:
                break;
            case 502:
                break;
            case 422:
                break;
            default:
                // Swal.fire({
                //     title: 'Error',
                //     showCancelButton: false,
                //     icon: 'error',
                //     html: error?.response?.data?.message,
                //     confirmButtonColor: '#3085d6',
                //     confirmButtonText: 'Ok'
                // }).then((result) => {
                    
                // });
                break;
        }
        return Promise.reject(error.response);
    }
    return Promise.resolve(error.response);
});


export const http = {
    getReq: function (endPoint, options = {}) {
        return API.get(endPoint, options);
    },
    createReq: function (endPoint, datas, options = {}) {
        return API.post(endPoint, datas, options);
    },
    updateReq: function (endPoint, datas, options = {}) {
        return API.post(endPoint, datas, options);
    },
    deleteReq: function (endPoint, options = {}) {
        return API.delete(endPoint, { data: options });
    },
}

