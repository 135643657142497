import React from 'react'
import ImageContent from '../../assets/images/car_bg.png'  
import TitleBoxComp from '../common/titleBox'
import './style.scss'

const SinglePictureBoxComp = ({toggle}) => {
  return (
    <>
        <section className='container single-picture-box my-3'>
            <div className='col-md-12'>
                <img src={ImageContent} className="w-100"/>
            </div>
            <div className='d-flex justify-content-center'>
                <TitleBoxComp title={'Take the road less travelled'}>
                    There are ways to explore the unknown within and outside. Nothing does it better than a road trip. And we, at Wrangler, are pulling out the stops with a specially curated one-of-a-kind journey for you. Camp and sleep under a sky full of stars, roast marshmallows over a bonfire, strum the guitar on a still moonlit night, or bask with elements in lush green landscapes – this freedom is yours for the taking.
                </TitleBoxComp>
            </div>
            <div className='d-flex justify-content-center align-items-center my-3'>
                <div className='col-md-4 col-12'>
                    <button onClick={()=>toggle()} className='px-4 mb-0 btn-submit w-100'>
                        Participate Now
                    </button>
                </div>
            </div> 
        </section>
    </>
  )
}

export default SinglePictureBoxComp